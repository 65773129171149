let skipBtn = document.querySelector('#skip-to-content')
if (skipBtn) {
  skipBtn.addEventListener('keydown', function (e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      const mainContainer = document.querySelector('html>body>main>.container')
      if (mainContainer) {
        let mainContent
        if (mainContainer.querySelectorAll(':scope > section').length > 1) {
          mainContent = mainContainer.querySelectorAll(':scope > section')[1]
        } else if (mainContainer.querySelector(':scope > article')) {
          mainContent = mainContainer.querySelector(':scope > article')
        }

        if (mainContent) {
          mainContent.scrollIntoView({ behavior: 'smooth', block: 'start' })
          mainContent.setAttribute('tabindex', '-1')
          mainContent.focus()
        }
      }
    }
  })
}
