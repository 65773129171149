;(function () {
  let slider = jQuery('.hero-slider')
  if (slider.hasClass('fade-animation')) {
    slider.slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      fade: true,
      cssEase: 'linear',
      adaptiveHeight: true,
    })
  } else {
    slider.slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      adaptiveHeight: true,
    })
  }
})()
