(function () {

  function transformPartners() {
    let partners = jQuery('.grid--partners .grid__item');
    for (let i = 0; i < partners.length; i += 3) {
      partners.slice(i, i + 3).wrapAll("<div class='column'></div>");
    }
  }

  if (window.innerWidth < 768) {
    transformPartners()
  }

    jQuery('.grid--partners')
    .on('destroy', function (ev, slick) {
      if (jQuery('.grid--partners .column').length) {
          jQuery('.grid--partners .grid__item').unwrap()
      }
      let carousel = jQuery(this),
        reinit = debounce(function () {
          if (slick.activeBreakpoint < window.innerWidth) {
            return;
          }
          carousel.slick(slick.options);
          window.removeEventListener('resize', reinit);
        }, 500);

      // Assign our debounced callback to window.resize.
      window.addEventListener('resize', reinit);
    })
    .slick({
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

  window.addEventListener('resize', function () {
    if (window.innerWidth < 768) {
      if (!jQuery('.grid--partners .column').length) {
        transformPartners()
      }
    }
  })

    jQuery('.events-list')
    .on('destroy', function (ev, slick) {
      console.log('destroy')
      if (jQuery('.grid--partners .column').length) {
          jQuery('.grid--partners .grid__item').unwrap()
      }
      let carousel = $(this),
        reinit = debounce(function () {
          if (slick.activeBreakpoint < window.innerWidth) {
            return;
          }
          carousel.slick(slick.options);
          window.removeEventListener('resize', reinit);
        }, 500);

      // Assign our debounced callback to window.resize.
      window.addEventListener('resize', reinit);
    })
    .slick({
      mobileFirst: true,
      slidesToShow: 1,
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

})()
