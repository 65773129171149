window.addEventListener('load', function () {
  let iframes = document.querySelectorAll('iframe')

  iframes.forEach(function (iframe) {
    if (!iframe.hasAttribute('title') || iframe.getAttribute('title').trim() === '') {
      iframe.setAttribute('title', 'Embedded content')
    }
  })

  let images = document.querySelectorAll('img')

  images.forEach(function (img) {
    if (!img.hasAttribute('alt') || img.getAttribute('alt').trim() === '') {
      img.setAttribute('alt', 'Image description')
    }
  })
})
