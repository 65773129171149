(function () {
  let slider = jQuery('.hero-slider')
  console.log('1')
  if (slider.hasClass('fade-animation')) {
    console.log('2')
    slider.slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      fade: true,
      cssEase: 'linear',
    })
  } else {
    console.log('3')
    slider.slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true
    })
  }
})()